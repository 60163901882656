import {
  Assortiment as AssortimentIcon,
  Orders as OrdersIcon,
  Employees as EmployeesIcon,
  Information as InformationIcon,
  Providers as ProvidersIcon,
  Supplies as SuppliesIcon,
  Marketing as MarketingIcon,
  Analytics as AnalyticsIcon,
  Profile as ProfileIcon,
  Reports as ReportsIcon,
  Registry as RegistryIcon,
} from 'components/icons';
import { useAuthContext } from 'contexts/AuthContext';

const ADMIN_LINKS = [
  [
    { icon: AssortimentIcon, key: 'assortiment', path: '/assortiment', desktopOnly: true },
    { icon: AssortimentIcon, key: 'stop-list', path: '/stop-list', desktopOnly: false },
    { icon: OrdersIcon, key: 'orders', path: '/orders', desktopOnly: false },
    { icon: EmployeesIcon, key: 'employees', path: '/employees', desktopOnly: false },
    { icon: InformationIcon, key: 'info', path: '/info', desktopOnly: false },
    { icon: EmployeesIcon, key: 'clients', path: '/clients', desktopOnly: false },
    { icon: ProfileIcon, key: 'cash-accounting', path: '/cash-accounting', desktopOnly: false },
  ],
  [
    { icon: ProvidersIcon, key: 'providers', path: '/providers', desktopOnly: true },
    { icon: SuppliesIcon, key: 'supplies', path: '/supplies', desktopOnly: true },
    { icon: MarketingIcon, key: 'marketing', path: '/marketing', desktopOnly: true },
    { icon: AnalyticsIcon, key: 'analytics', path: '/analytics', desktopOnly: false },
    { icon: ReportsIcon, key: 'reports', path: '/reports', desktopOnly: false },
    { icon: RegistryIcon, key: 'registry', path: '/registry', desktopOnly: true },
    { icon: SuppliesIcon, key: 'write-offs', path: '/write-offs', desktopOnly: true },
    { icon: EmployeesIcon, key: 'users', path: '/users', desktopOnly: true },
  ],
  [
    { icon: AnalyticsIcon, key: 'monitoring', path: '/monitoring', desktopOnly: false },
    { icon: InformationIcon, key: 'paid-delivery', path: '/paid-delivery', desktopOnly: true },
  ],
];

const PROVIDER_LINKS = [
  [
    { icon: AssortimentIcon, key: 'products', path: '/products', desktopOnly: true },
    { icon: SuppliesIcon, key: 'supplies', path: '/supplies', desktopOnly: true },
  ],
];

const useSidebarLinks = () => {
  const { user } = useAuthContext();

  if (user.role === 'provider') {
    return PROVIDER_LINKS;
  }

  const readable = Object.keys(user.acl).filter(key => user.acl[key].read);
  return ADMIN_LINKS.map(section => section.filter(link => readable.includes(link.key)));
};

export default useSidebarLinks;
